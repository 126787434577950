import React from 'react';
import { CloudinaryImageDisplay } from './CloudinaryImageDisplay';

interface ImageGridProps {
  images: string[];
  gridColumns?: number;
}

export const ImageGrid: React.FC<ImageGridProps> = ({ 
  images, 
  gridColumns = 4 
}) => {
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || 'dzb3a5brn';

  return (
    <div 
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
        gap: '10px',
        width: '100%',
        padding: '10px'
      }}
    >
      {images.map((imagePath, index) => (
        <div 
          key={index} 
          style={{
            aspectRatio: '1/1', 
            overflow: 'hidden', 
            borderRadius: '8px'
          }}
        >
          <CloudinaryImageDisplay 
            s3Path={imagePath} 
            width={300} 
            height={300} 
            cloudName={cloudName} 
          />
        </div>
      ))}
    </div>
  );
};
