import React, { useState } from 'react';

interface CloudinaryImageDisplayProps {
  s3Path: string;
  width: number;
  height: number;
  cloudName?: string;
}

export const CloudinaryImageDisplay: React.FC<CloudinaryImageDisplayProps> = ({
  s3Path, 
  width, 
  height, 
  cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || 'dzb3a5brn'
}) => {
  const [imageError, setImageError] = useState(false);

  // Construct full S3 URL without trailing slash
  const s3BaseUrl = (process.env.REACT_APP_S3_BASE_URL || 'https://blends-il-uploads.s3.il-central-1.amazonaws.com').replace(/\/$/, '');
  const fullS3Url = `${s3BaseUrl}/${s3Path}`;

  // Construct Cloudinary URL following Mixtiles pattern exactly
  const cloudinaryUrl = `https://res.cloudinary.com/${cloudName}/image/fetch/f_auto,q_auto:good/c_thumb,w_${width},h_${height}/${fullS3Url}`;

  const handleImageError = () => {
    console.error('Failed to load Cloudinary image:', cloudinaryUrl);
    console.error('Original S3 URL:', fullS3Url);
    setImageError(true);
  };

  if (imageError) {
    return (
      <div 
        style={{
          width: '100%', 
          height: '100%', 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f0f0',
          color: '#666'
        }}
      >
        Image Load Error
      </div>
    );
  }

  return (
    <img 
      src={cloudinaryUrl} 
      alt="Cloudinary optimized" 
      style={{
        width: '100%', 
        height: '100%', 
        objectFit: 'cover', 
        objectPosition: 'center'
      }} 
      loading="lazy"
      onError={handleImageError}
    />
  );
};