import React, { useState } from 'react';
import { Uploader } from '../components/Uploader/Uploader';
import { ImageGrid } from '../components/Uploader/ImageGrid';
import './Images.css';

const Images: React.FC = () => {
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  return (
    <div className="images-page">
      <h1>Image Upload</h1>
      <Uploader 
        onUploadComplete={(files) => {
          console.log('Uploaded files:', files);
          setUploadedImages(prevImages => [...prevImages, ...files]);
        }} 
      />
      {uploadedImages.length > 0 && (
        <div className="uploaded-images-grid">
          <h2>Uploaded Images</h2>
          <ImageGrid 
            images={uploadedImages} 
            gridColumns={4} 
          />
        </div>
      )}
    </div>
  );
};

export default Images;
