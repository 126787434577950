import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import './Uploader.css';

interface UploaderProps {
  onUploadComplete?: (fileNames: string[]) => void;
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';


export const Uploader: React.FC<UploaderProps> = ({ onUploadComplete }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setUploading(true);
    setError(null);

    try {
      console.log('API_BASE_URL:', API_BASE_URL);
      
      // Check file sizes before uploading
      const oversizedFiles = acceptedFiles.filter(file => file.size > MAX_FILE_SIZE);
      if (oversizedFiles.length > 0) {
        const fileNames = oversizedFiles.map(f => `${f.name} (${(f.size / (1024 * 1024)).toFixed(1)}MB)`).join(', ');
        throw new Error(`Files exceed 50MB limit: ${fileNames}`);
      }

      // First, get pre-signed URLs for each file
      const accessToken = await getAccessTokenSilently();
      console.log('Got access token:', accessToken ? 'Token received' : 'No token');

      const url = `${API_BASE_URL}/images/uploadS3`;
      console.log('Requesting pre-signed URLs from:', url);

      const filesMetadata = acceptedFiles.map(file => ({
        name: file.name,
        type: file.type
      }));

      const urlsResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ files: filesMetadata }),
      });

      if (!urlsResponse.ok) {
        const errorData = await urlsResponse.json().catch(() => null);
        throw new Error(
          errorData?.error || 
          errorData?.details || 
          `Failed to get upload URLs: ${urlsResponse.status} ${urlsResponse.statusText}`
        );
      }

      const { urls, files } = await urlsResponse.json();
      console.log('Got pre-signed URLs:', urls.length);

      // Upload files directly to S3 using pre-signed URLs
      const uploadPromises = acceptedFiles.map(async (file, index) => {
        console.log(`Uploading ${file.name} to S3...`);
        const uploadResponse = await fetch(urls[index], {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
            'Origin': window.location.origin
          }
        });

        if (!uploadResponse.ok) {
          throw new Error(`Failed to upload ${file.name} to S3: ${uploadResponse.status} ${uploadResponse.statusText}`);
        }

        console.log(`Successfully uploaded ${file.name} to S3`);
        return files[index];
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      console.log('All files uploaded successfully:', uploadedFiles);
      onUploadComplete?.(uploadedFiles);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to upload images');
    } finally {
      setUploading(false);
    }
  }, [onUploadComplete, getAccessTokenSilently]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
    multiple: true
  });

  return (
    <div className="uploader-container">
      <div 
        {...getRootProps()} 
        className={`dropzone ${isDragActive ? 'active' : ''} ${uploading ? 'uploading' : ''}`}
      >
        <input {...getInputProps()} />
        {uploading ? (
          <div className="upload-status">
            <div className="spinner"></div>
            <p>Uploading...</p>
          </div>
        ) : (
          <div className="upload-prompt">
            <i className="upload-icon">📁</i>
            <p>{isDragActive ? 'Drop the files here' : 'Drag & drop images here, or click to select'}</p>
          </div>
        )}
      </div>
      {error && <div className="upload-error">{error}</div>}
    </div>
  );
};
