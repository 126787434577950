import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './Sidebar.css';
import './Header.css';
import { FaHome, FaShoppingCart, FaBoxes, FaCog, FaSignOutAlt, FaImages } from 'react-icons/fa';

const Sidebar: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <nav className="sidebar">
      <ul>
        <li><NavLink to="/" end title="Dashboard"><FaHome /></NavLink></li>
        <li><NavLink to="/orders" title="Orders"><FaShoppingCart /></NavLink></li>
        <li><NavLink to="/products" title="Products"><FaBoxes /></NavLink></li>
        <li><NavLink to="/images" title="Images"><FaImages /></NavLink></li>
        <li><NavLink to="/settings" title="Settings"><FaCog /></NavLink></li>
        <li>
          <button
            className="logout-button"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            title="Log Out"
          >
            <FaSignOutAlt />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
